$colorScale: 5%;

// COLORS
$blurple50: rgb(62, 100, 255);
$blurple40:  lighten($blurple50, $colorScale);
$blurple30:  lighten($blurple40, $colorScale);
$blurple60: darken($blurple50, $colorScale);
$blurple70: darken($blurple60, $colorScale);
$primary40: $blurple40;
$primary30: $blurple30;
$primary: $blurple50;
$primary70: $blurple70;
$complete: #27aa80;
$text: #172b4d;
$disabled: #fad6d6;
$gray90: #767a83;
$gray80: #898c94;
$gray70: #a4a8b1;
$gray60: #B7BAC1;
$gray50: #C9C5C5;
$gray40: #D7D6D6;
$gray30: #e2e2e2;
$gray20: #f1f1f1;
$gray10: #f6f6f6;
$danger: #dd3f5b;
$danger10: #FBE6F3;
$border: $gray40;

// SPACING
$spacing-xxs: 2px;
$spacing-xs: 4px;
$spacing-sm: 8px;
$spacing-md: 12px;
$spacing-lg: 18px;
$spacing-xl: 24px;
$spacing-xxl: 32px;
$spacing-xxxl: 44px;

// SIZING
$sizing-xxs: 60px;
$sizing-xs: 100px;
$sizing-sm: 200px;
$sizing-md: 300px;
$sizing-lg: 500px;
$sizing-xl: 640px;
$sizing-xxl: 860px;
$sizing-xxxl: 1300px;

// BORDERS
$radius-card: 18px;
$radius-btn: 10px;
$radius-btn-sm: 6px;
$radius-modal: 24px;

// DISPLAY
$tablet-large-width: 1265px;
$tablet-width: $sizing-xxl;
$phone-width: $sizing-xl;

// ANIMATIONS
$snease: cubic-bezier(0.68, 0.54, 0.46, 1);
