@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@500;900&display=swap');
@import 'loaders.css/src/animations/ball-pulse.scss';
@import "variables.scss";
@import "utils.scss";

body {
  color: $text;
  margin: 0;
  font-family: 'Noto Sans TC', sans-serif !important;
  caret-color: $primary !important;
}
::selection {
  background-color: $primary;
  color: white;
}

[contenteditable] {
  -webkit-user-select: text;
  user-select: text;
}

h2 {
  font-size: 40px;
}

textarea {
  font-family: inherit;
  resize: none;
  border: none;
  background-color: transparent;
  caret-color: $primary !important;
  
  &::placeholder {
    color: $gray60;
  }
}

a {
  color: unset;
  text-decoration: unset;
  cursor: pointer;
}

p {
  margin: 10px 0;
  overflow-wrap: break-word;
  text-align: left;
}

label {
  font-size: 16px;
  display: block;
}

button, input {
  padding: 4px;
  border: 1px solid $disabled;
}

button, .btn {
  transition: all ease 120ms;
  &:active {
    transform: scale(.93);
  }
}

button, .btn {
  font-family: inherit;
  outline: none;
  background: transparent;
  user-select: none;
  border-radius: 5px;
  padding: 4px 12px;
  color: $primary;
  transition: all ease 120ms;
  cursor: pointer;
  border: none;
  
  &.jumbo {
    font-size: 30px;
    padding: 16px 40px;
    border-radius: 14px;
    background-color: white;
    color: $primary70;
  }
}

.btn-secondary {
  @extend .btn;
  background-color: white;
  box-shadow: 0 23px 100px 0 rgba(23, 43, 77, .5);
  
  &:hover {
    background-color: rgba(white, .9);
  }
}

.btn-naked {
  @extend .btn;
  color: white;
  &:hover {
    color: rgba(white, .9);
  }
}

button.active {
  color: $primary;
}

button.active:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50%;
  padding-top: 4px;
  border-bottom: 1px solid $primary;
}

input:focus {
  outline: none;
}

select {
  outline: none;
  height: 40px;
}

h1, h2 {
  font-weight: 700 !important;
}

code {
  background-color: $gray20;
  padding: 2px 12px;
  border-radius: 8px;
  color: $primary;
  font-weight: bold;

  span {
    color: $gray70; 
    font-weight: normal;
  }

  span.word {
    font-size: 14px;
    color: $primary;
    opacity: 1;
    font-weight: bold;
  }
}

.App {
  position: relative;
  height: 100vh;
}

.right {
  float: right;
}

.navbar {
  padding: 16px 0;
  max-width: 1440px;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
}

.landing {
  background-color: $primary;
  height: 100vh;

  img {
    width: 220px;
  }

  .flexer {
    width: 100%;
    height: 100%;
  }
}

.bottom-text {
  color: rgba(white, .8);
  margin-top: 24px;


  a {
    font-weight: bold;
    color: white;
    margin: 0 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}

.bottom-bar {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: $primary;
  font-size: 14px;
  text-align: center;
  padding: 0px 4px 6px;

  .emoji {
    color: white;
    margin-left: 8px;
    margin-right: 8px;
    font-size: 20px;
  }
}

.hidden {
  opacity: 0;
  position: absolute;
  left: -40;
}

.flexer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  button {
    margin-top: 80px;
  }
}

.shortcut-wrapper {
  margin-top: 20px;
  span {
    margin-left: 8px;
  }
}

.tooltip-activator {
  position: relative;

  &:hover .tooltip-shared {
    opacity: 1;
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tooltip-shared {
  opacity: 0;
  pointer-events: none;
  font-weight: normal;
  font-size: 11px;
  border-radius: 3px;
  padding: 0 6px 1px;
  color: white;
  background-color: black;
  position: absolute;
  box-shadow: 0 3px 12px 0 rgba(0,0,0, .3);
  transition: opacity 100ms linear;
}

.tooltip-explicit {
  @extend .tooltip-shared;
  max-width: 177px;
  min-width: 90px;
  right: 5%;
  top: -25px;
  z-index: 100;
  padding-top: 4px;
  padding-bottom: 6px;
  line-height: 1;

  &.left {
    left: 28px !important;
    right: auto;
    transform-origin: left;
  }

  &.right {
    transform-origin: right;
  }

  &.bottom-left {
    transform-origin: bottom left;
    top: auto !important;
    bottom: -26px !important;
    left: 0 !important;
    width: auto !important;

    &.wide {
      width: 120px !important;
    }
  }

  &.bottom-right {
    top: auto !important;
    right: 0 !important;
    left: auto !important;
    bottom: -26px;
    transform-origin: bottom right;
    width: 40px !important;
  }

  &.bottom {
    top: auto !important;
    left: auto;
    bottom: -18px;
    right: 50%;
    transform-origin: bottom;
  }
}

.tooltip {
  &:before {
    @extend .tooltip-shared;
    content: 'tooltip';
    left: 8px;
    top: -17px;
  }

  &:hover {
    &:before {
      pointer-events: auto;
      opacity: 1;
      // transform: translateY(0) scale(1);
    }
  }
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 0 20px;
  margin: 0 auto;
  max-width: 1840px;
}

.item {
  position: relative;
  z-index: 0;
  min-height: 30px;
  line-height: 1.88;
  margin-bottom: 4px;
  padding: 0;
  font-size: 15px;
  border-radius: 5px;
  z-index: 1;
  background-color: white;
  user-select: none;
  opacity: 1;
  transition: box-shadow 70ms ease-out;

  &:focus {
    background-color: rgba(62, 100, 255, .2);
    border: none;
    outline: none;

    .btn-subtle:hover {
      background-color: transparent !important;
    }
  }

  &:hover {
    cursor: grab;
    z-index: 3;

    .gripper {
      opacity: 1 !important;
      &.invsible {
        opacity: 0 !important;
        cursor: default !important;
      }
    }

    .ellipsis {
      opacity: 1 !important;
    }
  }

  &.significance-2 {
    .item-title {
      font-weight: bold;
      font-size: 15px;
      margin-top: -3px;
      opacity: 1;
    }
  }

  &.significance-3 {
    border: 1px solid $border;

    .item-title {
      font-weight: bold;
      font-size: 17px;
      color: black !important;
      opacity: 1;
      text-decoration: none;
    }

    .checkbox {
      display: none;
    }

    .item-inner {
      flex-direction: column;
      position: relative;
      padding: 0 8px;

      .gripper {
        position: absolute;
        left: -9px;
        top: 2px;
      }

      .ellipsis {
        right: -23px;
      }

      .text-holster {
        padding-right: 2px;
      }
    }

    .peripherals-holster {
      padding-top: 0;
      margin-top: -4px;
      margin-bottom: 8px
    }
  }

  .item-inner {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    opacity: 1;
    transition: transform 100ms ease-out, opacity 100ms linear;
    transform-origin: left;

    &.removed {
      transform: scale(.7);
      opacity: 0;
    }
  }

  .ellipsis {
    opacity: 0 !important;
    position: absolute;
    right: -19px;
    top: 0;
  }

  &[data-missed="true"] {
    .btn-subtle.checkbox {
      opacity: .16;
      // pointer-events: none;
      
      label {
        background-color: $gray60 !important;
      }
    }

    .item-title {
      color: rgba($gray40, .84);
      text-decoration: line-through;
      caret-color: $primary !important;

      &:focus {
        text-decoration: none !important;
      }

      &::placeholder {
        color: rgba($gray40, .84);
      }
    }
  }
}

.text-holster {
  flex-grow: 2;
  flex-shrink: 1;
  flex-basis: 0;
  padding-right: 4px;
}

.item-title {
  font-size: 14px;
  cursor: text;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-word;
  caret-color: $primary;
  padding: 6px 2px 4px;
  text-align: left;
  margin: 0;
  line-height: 1.36;
  
  &:focus {
    color:black;
    border: none;
    outline: none;
  }

  &.checked {
    opacity: .47;
    text-decoration: line-through;
    color: desaturate($primary, 50%) !important;
  }
}

.peripherals-holster {
  flex-grow: 0;
}

.new-hot-zone {
  justify-content: center;
  min-height: 300px;
  cursor: text;

  &:hover {
    .btn-item-new {
      opacity: .88;
      transform: scale(1);
    }
  }
}

button.btn-item-new {
  background-color: $gray10;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  border: none;
  font-size: 20px;
  transform: scale(.8);
  opacity: 0;
  transition: transform 240ms $snease, opacity 140ms linear;

  &:hover {
    opacity: 1 !important;
    transform: scale(1.08) !important;
  }

  &:active {
    transform: scale(.9) !important;
  }
}

.pretty.p-default {
  margin: 0 8px 0 2px;
}

.item-status {
  text-align: right;
}

.color-bar {
  width: 40px;
  height: 10px;
  border-radius: 5px;
}

.drop-wrapper {
  flex: 1 25%;
  width: 100%;
  height: 100%;
}

.col-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 20px;
  margin-left: 8px;
  margin-right: 8px;

  @include tablet {
    display: none;

    &.col-today {
      display: flex;
      margin-top: 67px;
    }
  }
}

.col-list {
  min-height: calc(100vh - 213px);
  padding: 8px;
  transition: background-color 30ms linear;
}

.col-header {
  position: relative;
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 2px;
  padding-left: 16px;
  padding-bottom: 10px;
  margin-top: 0;
  border-bottom: 1px solid $gray30;
  user-select: none;

  &.today-title .dow-text {
    color: $primary70 !important;
  }
}

.col-header .date {
  font-family: 'Noto Sans TC', sans-serif;
  margin-left: 6px;
  color: $gray70;
  font-weight: normal;
  font-size: 15px;
  transition: opacity 220ms ease;
}

.col-header .date[data-current="true"] {
  color: $gray90;
}

.col-header .current-day {
  position: absolute;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  left: 2px;
  top: 8px;
  background: radial-gradient(lighten(saturate($primary, 90%), 18%), $primary);
  
  &:before {
    font-family: 'Noto Sans TC', sans-serif;
    font-weight: normal;
    content: 'today';
    left: -12px;
    top: -22px;
  }
}

.col {
  min-height: 300px;
}

.highlight-region {
  background-color: $gray30;
}

.date-display {
  align-items: center;
  height: 96px;
  @include tablet {
    display: none;
  }
}

.page-header {
  display: inline-block;
  padding:0 20px 0;
  margin-bottom: 0;
  font-size: 34px;
  font-weight: normal;
  margin-top: 0;
  text-align: center;
  user-select: none;
}

.modal {
  background-color: white;
  border-radius: $radius-modal;
  margin: 48px 0 68px;
  min-height: 450px;
  width: 800px;
  outline: none;
  padding: 60px 80px;
  box-shadow: 70px 70px 800px 20px rgba(129,129,129, .14);
  position: relative;

  .modal-closer {
    position: absolute;
    top: 24px;
    right: 24px;
    border-radius: 50%;
    cursor: pointer;
    width: 50px;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 120ms ease;

    &:hover {
      background-color: $gray10;
    }
  }

  .modal-title {
    font-size: 37px;
    line-height: 1.25;
    border: none;
    width: 100%;
    &:focus {
      outline: none;
    }
  }

  .modal-description {
    font-size: 20px;
    margin-top: 16px;
    line-height: 1.7;
    width: 100%;
    min-height: 68vh;
    &:focus {
      border: none;
      outline: none;
    }
  }
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 8px;

  &:hover .line {
    border-color: $gray50 !important;
  }

  &:focus {
    outline: none;
    .line {
      border-color: $primary !important;
      box-shadow: 0 0 0 1px $primary;
    }
  }
  
  .line {
    width: 100%;
    border-bottom: 1px solid;
    border-color: $gray30;
    transition: all 100ms ease;

    &.dragging {
      border-color: $primary !important;
      box-shadow: 0 0 0 1px $primary, 0 4px 16px 0 rgba(0,0,0, .94);
    }
  }
}

.scrim {
  z-index: 3;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  cursor: default;
}

.popover {
  position: absolute;
  z-index: 4;
  left: 0;
  top: -6px;
  width: 140px;
  background-color: white;
  cursor: pointer;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 4px 33px rgba(0,0,0, .15);

  &.item-menu-popover {
     width: 250px;
  }

  &.resolution-selector {
    top: 31px;
    padding: 12px;

    .menu-item {
      width: 100%;

      &.selected {
        background-color: rgba($primary, .08);
        color: $primary;
      }
    }
  }

  &.last {
    right: 0;
    left: auto;
  }

  @include tablet {
    right: 0;
    left: auto;
  }

  .icon-left {
    margin-left: 6px;

    &.flex {
      width: 100%;

      .equal-width {
        text-align: center;
        border-radius: $radius-btn-sm;

        &:hover {
          background-color: rgba($primary30, .7);
          color: white;
        }

        &.active {
          background-color: $primary40;
          color: white;
        }
      }
    }
  }

  .significance-btn {
    @extend .flex;
    flex-direction: column;
    padding: 4px 8px;
    
    .shortcut {
      opacity: .6;
      font-size: 10px;
      margin-top: 4px;
      font-weight: normal;
    }
  }

  .option {
    width: 100%;
    padding: 12px;
    font-size: 12px;
    font-weight: bold;
    position: relative;
    display: flex;
    align-items: center;

    &:hover {
      background-color: $gray10;
      &.danger {
        background-color: lighten($danger10, 2%);
        height: 14px !important;
      }
    }

    &.danger {
      height: 14px !important;
    }

    &.full-height {
      padding: 0 0 0 12px;

      &:hover {
        background-color: transparent;
      }
    }

    code {
      background-color: transparent;
      position: absolute;
      right: 10px;
      top: 15px;
      padding: 0;
      color: $gray70;
      line-height: 1;
      display: flex;

      span {
        font-size: 12px;
        margin: 0;
        line-height: 1;
        padding: 0 0 0 1px !important;
        color: $gray50;
        transform: translateY(-2px);

        &.word {
          color: $gray70;
          
          &.symbol {
            font-size: 16px;
            line-height: .7;
            transform: translateY(-1px);
          }
        }

        &:hover {
          background-color: transparent;
        }
      }
    }
  }
}

.overlay {
  display: flex;
  z-index: 4;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: white;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 90ms linear;

  .modal {
    transform: translateY(14px) scale(.95);
    transition: transform 140ms $snease;
  }
}

.ReactModal__Overlay--after-open {
  opacity: 1;

  .modal {
    transform: translateX(0px) scale(1);
  }
}

.ReactModal__Overlay--before-close {
  opacity: 0;

  .modal {
    transform: translateY(14px) scale(.95);
  }
}

.close-btn-ctn {
  display: flex;
}

.close-btn {
  height: 40px;
  width: 35px;
  font-size: 20px;
  color: #031D2C;
  border: none;
  border-radius: 25px;
}

.close-btn:hover {
  background-color: $gray30;
}

button {
  cursor: pointer;

}

.btn-subtle {
  padding: 7px 6px;
  max-height: 15px;
  line-height: 1;
  border-radius: $radius-btn-sm;
  cursor: pointer;
  transition: background-color 90ms linear;
  user-select: none;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: 600;

  &:hover {
    background-color: $gray10;
  }

  &.checkbox label {
    margin-bottom: -3px !important;
  }

  &.gripper {
    transform: translateY(1px);
    cursor: grab;
    opacity: 0;
    margin-left: -17px;
    transition: opacity 90ms linear;

    &.no-grip {
      opacity: 0 !important;
      pointer-events: none;
    }
  }

  .tooltip-explicit {
    text-transform: none;
  }

  &.bordered {
    text-transform: uppercase;
    font-weight: bold;
    margin-top: 2px;
    font-size: 10px;
    border: 1px solid $border;
  }

  &.reset {
    border: 1px solid $primary;
    color: $primary70;
    margin-left: 8px;

    &:hover {
      background-color: rgba($primary30, .1);
    }
  }
}

.btn-subtle.checkbox[data-checked="true"] label {
  background-color: rgba($primary, .144) !important;
}

.peripherals-holster {
  display: flex;
  align-items: flex-start;
  padding-top: 6px;
}

.expander {
  padding: 5px 4px;
  margin-left: 3px;
}

.time, .expander {
  display: flex;
  font-size: 10px;
  border: 1px solid $gray30;
  padding: 4px;
  
  &.past {
    opacity: .3 !important;
  }

  &:hover {
    background-color: transparent;
  }
}

.time {
  font-weight: bold;
  color: $gray80;
  white-space: nowrap;
}

.splitter {
  display: flex;
}

.half {
  width: 50%;
}

.signout {
  position: absolute;
  right: 20px;
  top: 20px;
  .button {
    padding: 4px !important;
  }
}

.btn-clear {
  margin-left: 20px;
  background-color: $gray20;
}

.popover.popover-time {
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: auto;
  min-width: 185px;
  padding: 8px 12px;

  .react-time-picker__wrapper {
    border: 1px solid $gray30;
    border-radius: 8px;
    padding: 2px 10px 1px;
  }
}

.puffery {
  position: absolute;
  left: 4px;
  top: 40%;
  transform: translate(100%, -50%);
  height: 20px;
  width: 30px;
  overflow: visible;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 230ms 10ms ease-out;

  &.scootydoo {
    transform: translate(340%, -50%);
  }

  .puff {
    animation-name: puff;
    animation-duration: 160ms;
    animation-iteration-count: infinite;
    background: rgba($primary, .3);
    opacity: 1;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    top: 10px;
    position: absolute;
    transform-origin: bottom left;
  }

  .puff-1 {
    top: 10px;
    left: calc(50%);
  }

  .puff-2 {
    top: 10px;
    left: calc(50% - 7px);
    animation-delay: 40ms;
  }

  .puff-3 {
    top: 10px;
    left: calc(50% + 7px);
    animation-delay: 80ms;
  }

  .puff-4 {
    top: 10px;
    left: calc(50% - 14px);
    animation-delay: 120ms;
  }

  .puff-5 {
    top: 10px;
    left: calc(50% + 14px);
    animation-delay: 160ms;
  }
}

@keyframes puff {
  0% {
    transform: translateY(14px) scale(.1);
    opacity: .6;
  }
  90% {
    transform: translateY(-7px) scale(.7);
    opacity: .2;
  }
  100% {
    transform: translateY(-8px) scale(2.2);
    opacity: 0;
  }
}

.delete-animation {
  transform: translate(-2px, -3px);
}

.lid {
  transform-origin: 2px 5px;
  transition: 200ms all $snease;

  &.lid-hovered {
    transform: rotate(-38deg) translate(1px, -2px);
  }
}


// .arrow-clicked {
//   animation: chevronLeft 800ms 700ms;
// }

// @keyframes chevronLeft {
//   0% { transform: rotate(0deg) scale(1);}
//   10% { transform: rotate(20deg) }
//   30% { transform: rotate(-20deg) scale(1.2) }
//   50% {transform: rotate(20deg) scale(1.2)}
//   70% {transform: rotate(-20deg) }
//   100% {transform: rotate(0deg) scale(1)}
// }

.chevron {
  transition: transform 200ms ease;
  &:active {
    transform: scale(.7);
    svg path {
      stroke: $primary;
    }
  }
}

.chevron-right {
  pointer-events: none;
  transition: transform 300ms ease;
}

.chevron-left {
  pointer-events: none;
  transform: rotate(180deg);
  transition: transform 300ms ease;
}

.skeleton-box {
  display: inline-block;
  height: 14px;
  position: relative;
  overflow: hidden;
  background-color: $gray20;
  margin-top: 20px;
  border-radius: 20px;

  &:after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    transform: translateX(-100%);
    background-image: linear-gradient(
      90deg,
      rgba(#fff, 0) 0,
      rgba(#fff, 0.2) 20%,
      rgba(#fff, 0.5) 60%,
      rgba(#fff, 0)
    );
    animation: shimmer 400ms infinite;
    content: '';
  }

  @keyframes shimmer {
    100% {
      transform: translateX(100%);
    }
  }
}

.form-page {
  background-color: $primary30;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .form {
    width: 460px;
    background-color: white;
    border-radius: $radius-modal;
    overflow: hidden;
    box-shadow: 0 16px 200px 0 rgba($text, .3);

    .form-header {
      @extend .flexer;
      height: 160px;
      padding-top: 10px;
      background-color: $primary40;

      img {
        width: 100px;
      }
    }

    .form-internal {
      padding: 0 60px 40px;
    }

    h2 {
      color: $primary70;
      opacity: .84;
      margin: 20px 0 20px;
    }

    .subtitle {
      margin: -14px 0 30px;
      font-size: 20px;
    }

    label {
      opacity: .7;
    }

    input {
      width: calc(100% - 30px);
      border: 2px solid transparent;
      margin-top: 6px;
      margin-bottom: 20px;
      font-size: 18px;
      background-color: rgba($gray10, .9);
      border-radius: $radius-btn;
      padding: 16px 16px;

      &:hover {
        border: 2px solid rgba($primary30, .4);
      }

      &:focus {
        border: 2px solid $primary30;
      }

      &::placeholder {
        color: darken($primary70, 20%);
        opacity: .55;
        font-style: italic;
      }
    }

    button.btn-primary {
      width: 100% !important;
      padding: 13px;
      font-size: 20px;

      &:hover, &:focus {
        background-color: lighten($primary, 3%);
      }
    }

    .form-footer {
      font-size: 14px;
      margin-top: 14px;

      button {
        padding: 0 6px;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

.btn-primary {
  background-color: $primary;
  color: white;
}

.loader-hidden {
  display: none;
}

.loader-active {
  display: block;
  transform: scale(.5);
}

#app-nav {
  position: relative;
  margin: 0 auto;
  padding: 0 18px;

  .right {
    position: absolute;
    top: 0;
    right: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .left-btns-holster {
    position: absolute;
    top: 0px;
    left: 18px;
    display: flex;
    align-items: center;

    span {
      transition: all ease 120ms;
      &:active {
        transform: scale(.93);
      }
    }
  }

  .username {
    margin-right: 6px;
    display: inline-block;
    transform: translateY(-1px);
  }
}

.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

.space-between {
  justify-content: space-between;
}

.equal-width {
  flex: 1 1 0px;
}

.sig-wrap {
  width: 83px !important;
  position: absolute;
  right: 2px;
}

.nav-btns {
  display: flex;
  align-items: center;
  padding: 16px;

  @include tablet {
    display: none;
  }

  .tooltip-explicit {
    left: 50%;
    top: 3px;
    width: 125px;
  }
}

.form-error {
  border: 1px solid rgba($danger, .1);
  background-color: lighten($danger10, 2%);
  color: darken($danger, 10%);
  border-radius: $radius-btn;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 4px;
  margin-bottom: 12px;
}

.form-shake {
  animation: shake-animation 890ms $snease;
}

@keyframes shake-animation {
  from {
    transform: translate3d(0, 0, 0);
  }
  10% {
    transform: translate3d(-10px, 0, 0);
  }
  20% {
    transform: translate3d(10px, 0, 0);
  }
  30% {
    transform: translate3d(-10px, 0, 0);
  }
  40% {
    transform: translate3d(10px, 0, 0);
  }
  50% {
    transform: translate3d(-10px, 0, 0);
  }
  60% {
    transform: translate3d(10px, 0, 0);
  }
  70% {
    transform: translate3d(-10px, 0, 0);
  }
  80% {
    transform: translate3d(10px, 0, 0);
  }
  90% {
    transform: translate3d(-10px, 0, 0);
  }
  to {
    transform: translate3d(0, 0, 0);
  }
}

.year-item {
  border-radius: $radius-btn;
  border: 1px solid transparent;
  padding: 4px 8px;
  margin-bottom: 2px;
  cursor: pointer;

  &:hover {
    border-color: $border;
  }

  .date {
    color: $gray80;
    margin-left: $spacing-lg;
    font-size: 12px;
  }

  &.three {
    font-size: 18px;
    font-weight: bold;
    padding: 8px 8px;
    border-color: $border;
  }
}

.year-view {
  .col-wrapper {
    margin-right: 1px;
    margin-left: 2px;
  }

  .col-list {
    padding: 1px;
  }
}

.relative {
  position: relative;
}

.nav-selector {
  margin-top: -3px;
  z-index: 4;
}
